import { SECTION_STATUS } from '../../../section/model';

import * as models from '@biotope/shuriken-status-api'
import * as referentielModels from '@biotope/shuriken-ng-referentiel-api'

import _ from 'lodash';

require('../../../section/components/section-details/section-details.scss');


// see directiveFactory :  https://stackoverflow.com/questions/25215410/how-to-implement-ng-idirectivefactory-in-typescript

// pb with typescript ?? : https://github.com/schmod/babel-plugin-angularjs-annotate/pull/55

//export default SectionDetailsDirective;

export default SectionDetailsDirective;

/*export default directives;
/*
module directives
{
    export class SectionDetailsDirective implements ng.IDirective
    {
        public restrict = 'E';
        public templateUrl = './section-details.html';
        public scope = {
          publicationProvider: '&publication',
          initialSectionProvider: '&initialSection',
          sectionStateProvider: '&sectionState',
          onSubmitFn: '&onSubmit',
          onCreateFn: '&onCreate',
          onChangeFn: '&onChange',
          onDeleteFn: '&onDelete'
        };

        public bindToController = true;
        public controller =  SectionDetailsController;
        public controllerAs = 'sectionDetailsCtrl';
        public replace = true;


        public link($scope: ng.IScope, element: JQuery, attributes: ng.IAttributes)
        {
            element.text("Hello world");
        }
    }
} */

/* @ngInject */
/*
function SectionDetailsDirective () : ng.IDirective {
    var directive: ng.IDirective = <ng.IDirective>{};
    directive.restrict = 'E';
    directive.template = require('./section-details.html');
    directive.scope = {
      'publicationProvider': '&publication',
      'initialSectionProvider': '&initialSection',
      'sectionStateProvider': '&sectionState',
      'onSubmitFn': '&onSubmit',
      'onCreateFn': '&onCreate',
      'onChangeFn': '&onChange',
      'onDeleteFn': '&onDelete'
    };
    directive.bindToController = true;
    directive.controller =  SectionDetailsController;
    directive.controllerAs = 'sectionDetailsCtrl';
    directive.replace = true;

    return directive;
}*/



/** @ngInject */
function SectionDetailsDirective() {
  return {
    restrict: 'E',
    scope: {
      publicationProvider: '&publication',
      initialSectionProvider: '&initialSection',
      sectionStateProvider: '&sectionState',
      isModeratorProvider: '&isModerator',
      isNewProvider: '&isNew',
      onSubmitFn: '&onSubmit',
      onCreateFn: '&onCreate',
      onChangeFn: '&onChange',
      onDeleteFn: '&onDelete'
    },
    bindToController: true,
    controller: SectionDetailsController,
    controllerAs: 'sectionDetailsCtrl',
    template: require('../../../section/components/section-details/section-details.html'),
    replace: true
  };
}

/** @ngInject */
function SectionDetailsController($scope, $timeout, SectionService, LxNotificationService, SectionServiceStore) {
  var sectionDetailsCtrl = this;

  sectionDetailsCtrl.section = {};
  sectionDetailsCtrl.getSectionStatusValues = getSectionStatusValues;
  sectionDetailsCtrl.onCreate = onCreate;
  sectionDetailsCtrl.onSubmit = onSubmit;
  sectionDetailsCtrl.onChange = onChange;
  //sectionDetailsCtrl.onCreate = onCreate;
  sectionDetailsCtrl.onConsolidate = onConsolidate;
  sectionDetailsCtrl.onDelete = onDelete;
  //sectionDetailsCtrl.creationInProgress = creationInProgress;
  sectionDetailsCtrl.comment = null;
  sectionDetailsCtrl.isFormValid = isFormValid;

  $scope.$watch(sectionDetailsCtrl.initialSectionProvider, (initialSection) => {
    if (!initialSection) {
      return;
    }

    // Create a copy of the immutable initialPublication
    sectionDetailsCtrl.section = _.defaults({}, initialSection);
    sectionDetailsCtrl.onChange();
  });

  $scope.$watch(sectionDetailsCtrl.sectionStateProvider, (sectionState) => {
    if (!sectionState) {
      return;
    }
    sectionDetailsCtrl.sectionState = sectionState;

  });

  $scope.$watch(sectionDetailsCtrl.isModeratorProvider, (isModerator) => {
    sectionDetailsCtrl.isModerator = isModerator;
  });

  $scope.$watch(sectionDetailsCtrl.isNewProvider, (isNew) => {
    sectionDetailsCtrl.isNew = isNew;
  });

  function onCreate() {
    //var sectionFormData = _.mapValues(_.pick($scope.sectionDetailsForm, ['statusTypeSelect', 'terrUnitSelect', 'statusSelect', 'txGroupSelect']), '$viewValue');
    let sectionFormData: models.Section = _.mapValues(_.pick($scope.sectionDetailsForm, ['statusType', 'status', 'comment']), '$viewValue');
    let txGroup: referentielModels.TerrUnit = _.mapValues(_.pick($scope.sectionDetailsForm, ['txGroup']), '$viewValue').txGroup;
    sectionFormData.txGroupKey = txGroup.key;

    let terrUnit: referentielModels.TerrUnit = _.mapValues(_.pick($scope.sectionDetailsForm, ['terrUnit']), '$viewValue').terrUnit;
    sectionFormData.terrUnitKey = terrUnit.key;
    // Pas besoin de relayer l'info au container principal, ce component peut faire la sauvegarde
    //sectionDetailsCtrl.onSubmitFn()(sectionDetailsCtrl.section.id, sectionForm, $scope.sectionDetailsForm);
    sectionDetailsCtrl.onCreateFn()(sectionFormData, $scope.sectionDetailsForm);
  }

  function onSubmit() {
    sectionDetailsCtrl.onSubmitFn()($scope.sectionDetailsForm);
  }

  function onChange() {
    if (!sectionDetailsCtrl.section) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {

      let section: models.Section = _.mapValues(_.pick($scope.sectionDetailsForm, ['id', 'statusType', 'terrUnit', 'status', 'txGroup', 'comment']), '$viewValue');

      if(section == null) {
        let terrUnit: referentielModels.TerrUnit = _.mapValues(_.pick($scope.sectionDetailsForm, ['terrUnit']), '$viewValue').terrUnit;
        section.terrUnitKey = terrUnit.key;
      }

      sectionDetailsCtrl.section =  _.defaults(section, sectionDetailsCtrl.section);

      //console.log(sectionDetailsCtrl.section);

      sectionDetailsCtrl.onChangeFn()({
        data: {
          status: sectionDetailsCtrl.section.status,
          comment: sectionDetailsCtrl.section.comment
        },
        formValid: isFormValid()
      });
    });
  }

  function onConsolidate() {
      SectionService.consolidateSection(sectionDetailsCtrl.section.id);
  }

  function onDelete() {
    LxNotificationService.confirm('Attention', 'Êtes-vous sur de vouloir supprimer la section ?',
              {cancel: 'Non', ok: 'Oui'},
              (answer) => (answer &&   SectionService.deleteSection(sectionDetailsCtrl.section.id)));
  }

  function isFormValid() {

    sectionDetailsCtrl.formValid = !!(sectionDetailsCtrl.section.statusType &&
      sectionDetailsCtrl.section.terrUnit &&
      sectionDetailsCtrl.section.txGroup)
      || (!sectionDetailsCtrl.isNew);

    return sectionDetailsCtrl.formValid;

    //return true;
  }

  function getSectionStatusValues() {
    var tab = SECTION_STATUS.VALUES;

    /*if (sectionDetailsCtrl.creationInProgress()) {
      tab = [SECTION_STATUS.VALUES[0]];
    }*/

    return tab;
  }

  function getStatusValues(){
    return STATUS.VALUES
  }


}
